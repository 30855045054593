import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import EstudaAPI from "../../services/EstudaAPI"
import styles from './UsuarioTurmaTimeline.module.scss'

export default () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        EstudaAPI.usuario.turma.timeline().then(function (response) {
            setData(response)
            setLoading(false)
        }).catch(function (response) {
            setError(true)
            setLoading(false)
        })
    }, [])

    if (loading) {
        return ("caregando...");
    }

    return (
        <div>
            {data.map(timeline => (
                <div class="card text-dark bg-white mb-3">
                    <div class="card-header d-flex align-items-center gap-2">
                        <img src={timeline.usuario.avatar} alt="mdo" width="16" height="16" class="rounded-circle"/>
                        <span>{timeline.usuario.name}</span>
                    </div>

                    <div class="card-body">
                        <h3>{timeline.resolver.titulo}</h3>

                        <p class="d-flex align-items-center gap-1">
                            <span class="fa fa-calendar-o fa-fw"></span>
                            {timeline.resolver.dataInicio} até {timeline.resolver.dataFim}

                            {(timeline.resolver.tempoMaximo > 0) && (
                                <>
                                    <span class="fa fa-clock-o fa-fw"></span>
                                    {timeline.resolver.tempoMaximo}m
                                </>
                            )}
                        </p>

                        <p>
                            <span class="fa fa-pencil fa-fw"></span>
                            <b>{timeline.resolver.questoesQuantidade}</b> Questões
                        </p>

                        <Link to="/" className="btn btn-success">Ver Resultado</Link>
                    </div>
                </div>
            ))}
        </div>
    )
}
