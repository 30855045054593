import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import UsuarioTurmaAlunos from "../../components/UsuarioTurmaAlunos"
import UsuarioTurmaTimeline from "../../components/UsuarioTurmaTimeline"
import EstudaAPI from "../../services/EstudaAPI"

export default () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        EstudaAPI.usuario.empresa.all().then(function (response) {
            setData(response)
            setLoading(false)
        }).catch(function (response) {
            setError(true)
            setLoading(false)
        })
    }, [])

    if (loading) {
        return ("caregando...");
    }

    return (
        <>
            <h1>Acessar Instituição</h1>

            <div class="container">
                <div style={{backgroundColor: '#fff'}}>
                    Filtros...
                </div>

                <div class="mt-3">
                    // todo
                </div>

                <div class="alert alert-default d-flex align-items-center gap-1 mt-3">
                    <i class="fa fa-info-circle fa-fw"></i>
                    Para acessar o seu Painel do Aluno
                    <Link class="btn btn-sm btn-info" to="/">clique aqui</Link>
                </div>
            </div>
        </>
    )
}
