import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import EstudaAPI from "../../services/EstudaAPI"
import styles from './UsuarioTurmas.module.scss'
import React from "react"
import ContentLoader from "react-content-loader"

export default (props) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const fetchAPI = async () => {
        const data = await EstudaAPI.usuario.turma.all();

        if (!data) {
            setError(true)
            setLoading(false)
            return;
        }

        setData(data)
        setLoading(false)
    }

    const Loading = () => {
        return (
            <ContentLoader
            speed={2}
            width="100%"
            height={300}
            backgroundColor="#ffffff"
            foregroundColor="#d5d5d5"
            className={styles.turma}
        >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="300" />
        </ContentLoader>
        )
    };

    useEffect(() => {
        fetchAPI();
    }, [])

    if (loading) {
        return (
            <div className={styles.turmas}>
                {
                    new Array(6).fill(" ").map((_, i) => {
                        return <Loading/>;
                    })
                }
            </div>
        );
    }

    return (
        <div className={styles.turmas}>
            {data.map(turma => (
                <Link key={turma.id} to={`/usuarios/turma/${turma.id}`}>
                    <div className={styles.turma}>
                        <img src={turma.empresa.image} alt=""/>

                        <div>
                            <p>{turma.name}</p>
                            <p>{turma.empresa.name}</p>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    )
}
