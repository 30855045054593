import { useMemo } from "react"

export default () => {
    useMemo(() => {
        document.title = "Pagina Inicial - Estuda.com"
      })

    return (
        <>
            <h1>Grupos de Estudo</h1>

            <div>
                Acesse seu grupo de estudo
            </div>

            <div>
                Grupos que faz parte
            </div>

            <div>
                Grupos publicos
            </div>
        </>
    )
}
