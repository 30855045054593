import { Link } from "react-router-dom"
import UsuarioTurmas from "../../components/UsuarioTurmas"

export default () => {
    return (
        <>
            <h1>Minhas Turmas</h1>

            <UsuarioTurmas/>

            <div class="alert alert-default d-flex align-items-center gap-1 mt-3">
                <i class="fa fa-info-circle fa-fw"></i>
                Procurando os Grupos de Estudo?
                <Link to="/grupos" class="btn btn-sm btn-info">Acesse Aqui</Link>
            </div>
        </>
    )
}
