import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Layout from "./layout";
import UsuarioIndex from "./pages/usuario/index";
import UsuarioTurmas from "./pages/usuario/turmas";
import UsuarioTurma from "./pages/usuario/turma";
import EmpresasLogin from "./pages/empresa/login";
import QuestoesBuscar from "./pages/questoes/buscar";
import Grupos from "./pages/grupos";

export default function App() {
  return (
    <div className="App" style={{
      backgroundColor: "#f5f5f5"
    }}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout component={UsuarioIndex}/>} />
          <Route path="/questoes/buscar" element={<Layout component={QuestoesBuscar}/>} />
          <Route path="/grupos" element={<Layout component={Grupos}/>} />

          <Route path="/usuarios/turmas" element={<Layout component={UsuarioTurmas}/>} />
          <Route path="/usuarios/turma/:turmaId" element={<Layout component={UsuarioTurma}/>} />


          <Route path="/empresas/login" element={<Layout component={EmpresasLogin}/>} />
        </Routes>
      </Router>
    </div>
  );
}
