import { useMemo } from "react";
import Template from "./Template";

export default ({component: Component}) => {
    useMemo(() => {
        document.title = "Estuda.com"
    })

    return (
        <Template>
            <Component/>
        </Template>
    )
}
