import { Link } from "react-router-dom";
import HeaderMenu from "../HeaderMenu";

export default () => {
  return (
    <header class="" style={{
      backgroundColor: '#35A2DB',
    }}>
      <div class="container-fluid d-grid gap-3 align-items-center" style={{
          gridTemplateColumns: '1fr auto'
      }}>
        <div class="d-flex align-items-center gap-3">
            <Link to="/">
                <img src="https://cdn.estuda.com.br/design/1/logo_branco.png" alt="mdo" width="140" height="32"/>
            </Link>

            <HeaderMenu/>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown position-relative">
            <a
              class="text-reset me-3"
              href="#"
              id="navbarDropdownMenuLink"
              data-bs-toggle="dropdown"
              role="button"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-bell"></i>
              <span class="badge rounded-pill badge-notification bg-danger  translate-middle">19</span>
            </a>
            <ul
              class="dropdown-menu text-small shadow"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li>
                <a class="dropdown-item" href="#">Some news</a>
              </li>
              <li>
                <a class="dropdown-item" href="#">Another news</a>
              </li>
              <li>
                <a class="dropdown-item" href="#">Something else here</a>
              </li>
            </ul>
          </div>

          <div class="flex-shrink-0 dropdown">
            <a href="#" class="d-block link-dark text-decoration-none" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="https://img.estuda.com/img/200x200/3/0/png/https://d23vy2bv3rsfba.cloudfront.net/usuarios_fotos/5868215_f9d8b41ac526626b0694bdf9609b501b_5868215.jpeg?1649766870.23680412mar03" alt="mdo" width="32" height="32" class="rounded-circle"/>
            </a>
            <ul class="dropdown-menu-custom dropdown-menu dropdown-menu-light text-small shadow" aria-labelledby="dropdownUser2">
              <li><a class="dropdown-item" href="#"><span class="fa fa-tasks"></span> 0 Questões hoje</a></li>
              <li><a class="dropdown-item" href="#"><span class="fa fa-star text-success"></span> Assinante Premium</a></li>
              <li><a class="dropdown-item" href="#"><span class="fa fa-cog"></span> Meus Dados</a></li>
              <li><a class="dropdown-item" href="#"><span class="fa fa-graduation-cap"></span> Categorizar Questões</a></li>
              <li><a class="dropdown-item" href="#"><span class="fa fa-power-off"></span> Sair</a></li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
