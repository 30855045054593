import { useState } from 'react';
import styles from './TemplateFooter.module.scss'

export default () => {
    const [footerState, setFooterState] = useState(false)

    return (
        <footer className="page-footer font-small blue pt-4">
            <div className={styles.buttonToOpen}>
                <span onClick={() => {setFooterState(!footerState)}}>
                    <img src="https://cdn.estuda.com.br/assets/estudavest/img/logo.png"/>
                </span>
            </div>

            <div className={`container-fluid text-center text-md-left ${styles.footer} ${!footerState && `d-none`}`}>
                <div className="row">
                    <div className="col-md-6 mt-md-0 mt-3">
                        <h5 className="text-uppercase">Footer Content</h5>
                        <p>Here you can use rows and columns to organize your footer content.</p>
                        <p>TDOMAIN: {process.env.REACT_APP_TDOMAIN}</p>
                    </div>

                    <hr className="clearfix w-100 d-md-none pb-0"/>

                    <div className="col-md-3 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#!">Link 1</a></li>
                            <li><a href="#!">Link 2</a></li>
                            <li><a href="#!">Link 3</a></li>
                            <li><a href="#!">Link 4</a></li>
                        </ul>
                    </div>

                    <div className="col-md-3 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#!">Link 1</a></li>
                            <li><a href="#!">Link 2</a></li>
                            <li><a href="#!">Link 3</a></li>
                            <li><a href="#!">Link 4</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}
