import EstudaAPI from "../../EstudaAPI";

export default {
    async all(id, force) {
        if (!EstudaAPI.instance.usuarioTurmas || force) {
            EstudaAPI.instance.usuarioTurmas = [
                {
                    id: 1,
                    name: 'Turma EM 1º Ano ',
                    empresa: {
                        name: 'Escola Estuda.com',
                        image: 'https://d23vy2bv3rsfba.cloudfront.net/empresas_logos/0_7848cdf684b83b1717e013e1533178a3_20029.png'
                    }
                },
                {
                    id: 2,
                    name: 'Turma EM 3º Ano ',
                    empresa: {
                        name: 'Simulados FTD',
                        image: 'https://d23vy2bv3rsfba.cloudfront.net/empresas_logos/0_ebc9a8dbacabda5412193832e04578c7_9.png'
                    }
                }
            ]
        }

        return EstudaAPI.instance.usuarioTurmas
    },

    async find(id) {

    },

    async timeline(force) {
        if (!EstudaAPI.instance.usuarioTurmaTimeline || force) {
            EstudaAPI.instance.usuarioTurmaTimeline = [
                {
                    type: 'resolver',
                    usuario: {
                        name: 'Leonardo Teste',
                        avatar: 'https://img.estuda.com/img/200x200/3/0/png/https://d23vy2bv3rsfba.cloudfront.net/usuarios_fotos/5868215_f9d8b41ac526626b0694bdf9609b501b_5868215.jpeg?1649766870.23680412mar03'
                    },
                    resolver: {
                        id: 500,
                        resolverResolver: 300,
                        dataInicio: '2022-06-03 09:23:00',
                        dataFim: '2022-06-17 23:59:00',
                        tempoInicio: 0,
                        tempoMaximo: 60,
                        titulo: 'Prova de Matemática EM 2º Ano ',
                        questoesQuantidade: 4
                    }
                }
            ]
        }

        return EstudaAPI.instance.usuarioTurmaTimeline
    },

    async alunos(id, force) {
        if (!EstudaAPI.instance.usuarioTurmaAlunos || force) {
            EstudaAPI.instance.usuarioTurmaAlunos = [
                {
                    id: 656597,
                    name: 'Alan Goes',
                    avatar: 'https://lh3.googleusercontent.com/a-/AOh14GiGUA9mXgR2d8VbnF2_DBj4TQLMiQMvIgZOZDTv=s96-c',
                    admin: 0
                },
                {
                    id: 656597,
                    name: 'Guilherme Santana Coelho',
                    avatar: 'https://lh3.googleusercontent.com/a-/AOh14GiGUA9mXgR2d8VbnF2_DBj4TQLMiQMvIgZOZDTv=s96-c',
                    admin: 1
                },
                {
                    id: 656597,
                    name: 'Diego',
                    avatar: 'https://lh3.googleusercontent.com/a-/AOh14GiGUA9mXgR2d8VbnF2_DBj4TQLMiQMvIgZOZDTv=s96-c',
                    admin: 0
                }
            ]
        }

        return EstudaAPI.instance.usuarioTurmaAlunos
    }
}
