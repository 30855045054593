import axios from 'axios';
import UsuarioTurma from './Usuario/Turma';
import usuarioEmpresa from './Usuario/Turma';

import EmpresaTurma from './Empresa/Turma';
import Layout from './Layout';

const baseURL = "https://api.estuda.com";
const api = axios.create({ baseURL });
const instance = {}

export default {
    instance,
    layout: new Layout(),

    usuario: {
        turma: UsuarioTurma,
        empresa: usuarioEmpresa
    },

    empresa: {
        turma: new EmpresaTurma(),
    }
}
