import TemplateHeader from "../components/template/TemplateHeader";
import TemplateFooter from "../components/template/TemplateFooter";
import TemplateSubHeader from "../components/template/TemplateSubHeader";

export default ({children}) => {
    return (
        <>
            <TemplateHeader/>
            <TemplateSubHeader/>
            
            <div class="container">
                {children}
            </div>
            
            <TemplateFooter/>
        </>
    );
}