import { Link } from "react-router-dom";

const EXCLUDE_IN_PAGES = [
    "/empresas/login"
];

export default () => {
  return !EXCLUDE_IN_PAGES.includes(window.location.pathname) ? (
    <div class="py-3 mb-3 border-bottom" style={{
      backgroundColor: '#ffffff',
    }}>
      <div class="container d-grid gap-3 align-items-center" style={{
          gridTemplateColumns: 'auto auto 1fr'
      }}>
          <div>
            <a href="#" class="d-block link-dark text-decoration-none" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="https://img.estuda.com/img/200x200/3/0/png/https://d23vy2bv3rsfba.cloudfront.net/usuarios_fotos/5868215_f9d8b41ac526626b0694bdf9609b501b_5868215.jpeg?1649766870.23680412mar03" alt="mdo" width="70" height="70" class="rounded-circle"/>
            </a>
          </div>

          <div>
            <h4 class="m-0 py-1">SnowRunescape</h4>

            <div class="d-flex justify-content-end gap-3 align-items-center">
              <span>0 questões hoje</span>
              <a href="teste" class="text-warning text-decoration-none"><i class="fa fa-star"></i> Você é premium</a>

              <Link to="/empresas/login" class="btn btn-sm btn-outline-default">Acessar como professor <i class="fa fa-refresh"></i></Link>
            </div>
          </div>

          <div class="d-flex justify-content-end gap-3">
            <a href="" class="btn btn-lg btn-info"><i class="fa fa-gamepad"></i> Mural de Medalhas</a>
            <a href="" class="btn btn-lg btn-outline-default"><i class="fa fa-question-circle-o"></i> Ajuda</a>
          </div>
      </div>
    </div>
  ) : null;
}
