import { useEffect, useState } from "react"
import EstudaAPI from "../../services/EstudaAPI"

export default () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        EstudaAPI.usuario.turma.alunos().then(function (response) {
            setData(response)
            setLoading(false)
        }).catch(function (response) {
            setError(true)
            setLoading(false)
        })
    }, [])

    return (
        <div class="card text-dark bg-white mb-3">
            <div class="card-header">Alunos</div>
            <div class="card-body">
                {loading ? "Carregando..." : data.map(aluno => (
                    <p>{aluno.name}</p>
                ))}
            </div>
        </div>
    )
}
