export default () => {
    return (
        <div class="row">
            <div class="col-md-6 col-sm-3 col-xs-5">
                <a href="questoes_categorias" class="card card-body text-center text-white bg-warning">
                    <span class="fa fa-list fa-4x"></span>

                    <p>Questões</p>
                </a>
            </div>

            <div class="col-md-6 col-sm-3 col-xs-5">
                <a href="questoes_categorias" class="card card-body text-center text-black-50">
                    <span class="fa fa-list fa-4x"></span>

                    <p>Provas</p>
                </a>
            </div>

            <div class="col-md-6 col-sm-3 col-xs-5">
                <a href="questoes_categorias" class="card card-body text-center text-black-50">
                    <span class="fa fa-list fa-4x"></span>

                    <p>Plano Adaptativo</p>
                </a>
            </div>

            <div class="col-md-6 col-sm-3 col-xs-5">
                <a href="questoes_categorias" class="card card-body text-center text-black-50">
                    <span class="fa fa-list fa-4x"></span>

                    <p>Plano de Estudo</p>
                </a>
            </div>
        </div>
    )
}
