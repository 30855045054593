import { useMemo } from "react"
import Banner from "../../components/Banner"
import Botoes from "../../components/Botoes"

export default () => {
    useMemo(() => {
        document.title = "Pagina Inicial - Estuda.com"
    })

    return (
        <>
            <Banner/>

            <div class="d-flex">
                <Botoes/>

                <div class="d-flex gap-2">
                    <div class="card text-dark bg-white mb-3">
                        <div class="card-header">Temas de Redação</div>
                        <div class="card-body">
                            dsa
                        </div>
                    </div>

                    <div class="card text-dark bg-white mb-3">
                        <div class="card-header">Minhas Turmas</div>
                        <div class="card-body">
                            dsa
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h2>
                    Estatísticas <small>(últimos 7 dias)</small>
                </h2>

                <div class="alert alert-default d-flex align-items-center gap-1 mt-3">
                    <i class="fa fa-info-circle fa-fw"></i>
                    Você não respondeu nenhuma questão nos últimos 7 dias
                </div>

                // Estatisticas
            </div>

            <div>
                <h2>
                    Últimas novidades
                </h2>

                <div className="row">
                    <div className="col col-sm-8 col-xs-12">
                        <div>
                            // Noticias timeline
                        </div>

                        <div>
                            // Desafio do dia
                        </div>

                        <div>
                            // Desafio do dia
                        </div>

                        <div>
                            // 2 videos
                        </div>

                        <div>
                            // Simulado treino da empresa 82
                        </div>

                        <div>
                            // 2 redação
                        </div>

                        <div>
                            // 2 questoes
                        </div>
                    </div>

                    <div className="col col-sm-4 col-xs-12">
                        <div>
                            // Iniciar tour
                        </div>

                        <div>
                            // ranking
                        </div>

                        <div>
                            // Instale o app *grátis*
                        </div>

                        <div>
                            // Acesse nossas redes sociais
                        </div>

                        <div>
                            // Participe da nossa pesquisa
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
