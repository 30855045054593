import { useState, useEffect, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import EstudaAPI from '../../services/EstudaAPI'

export default (props) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const menuSelected = (menu) => {
        const pathname = window.location.pathname;

        if (!menu.dropdown) {
            return menu.url == pathname
        }

        return false;
    }

    useEffect (() => {
        EstudaAPI.layout.getHeaderMenus().then(function (response) {
            setData(response)
            setLoading(false)
        }).catch(function (response) {
            setError(true)
            setLoading(false)
        })
    }, [])

    if (loading) {
        return ("caregando...");
    }

    return (
        <ul class="nav col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            {data.map(menu => (
                <li class="nav-item dropdown">
                    {menu.dropdown ? <a class={`nav-link btn ${menuSelected(menu) ? "btn-primary-dark" : ""} nav-link px-2 text-white px-3 py-3 rounded-0`} id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class={`fa ${menu.icon}`}></i> {menu.name}
                    </a> : <Link to={menu.url} class={`nav-link btn ${menuSelected(menu) ? "btn-primary-dark" : ""} nav-link px-2 text-white px-3 py-3 rounded-0`}>
                        <i class={`fa ${menu.icon}`}></i> {menu.name}
                    </Link>}

                    {menu.dropdown ? <ul className="dropdown-menu-custom dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                        {menu.dropdown.map(submenu => (
                            <li><Link to={submenu.url} class="dropdown-item"><i class={`fa ${submenu.icon}`}></i> {submenu.name}</Link></li>
                        ))}
                    </ul> : null}
                </li>
            ))}
        </ul>
    )
}
