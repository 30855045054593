import EstudaAPI from "../EstudaAPI";

export default class Turma {
    all(force)
    {
        console.log('edit')
        // TODO
    }

    edit()
    {
        console.log('edit')
        // TODO
    }

    del()
    {
        console.log('del')
        // TODO
    }
}
