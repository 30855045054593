import EstudaAPI from "./EstudaAPI";

export default class Layout {
    async getHeaderMenus(force) {
        if (!EstudaAPI.instance.menus || force) {
            EstudaAPI.instance.menus = [
                {
                    name: 'Painel',
                    icon: 'fa-desktop',
                    url: '/'
                },
                {
                    name: 'Testar Conhecimento',
                    icon: 'fa-book',
                    dropdown: [
                        {
                            name: 'Questões',
                            icon: 'fa-list',
                            url: '/questoes/buscar'
                        },
                        {
                            name: 'Provas',
                            icon: 'fa-th',
                            url: '/'
                        },
                        {
                            name: 'Simulados',
                            icon: 'fa-list-ol',
                            url: '/'
                        },
                        {
                            name: 'Gerar Simulados',
                            icon: 'fa-plus-square',
                            url: '/'
                        },
                        {
                            name: 'Simulados TRI',
                            icon: 'fa-list-ol',
                            url: '/'
                        },
                        {
                            name: 'Redação',
                            icon: 'fa-pencil',
                            url: '/'
                        }
                    ]
                },
                {
                    name: 'Minhas Turmas',
                    icon: 'fa-graduation-cap',
                    url: '/usuarios/turmas'
                },
                {
                    name: 'Estatísticas',
                    icon: 'fa-graduation-cap',
                    dropdown: [
                        {
                            name: 'Desempenho Geral',
                            icon: 'fa-bar-chart',
                            url: '/'
                        },
                        {
                            name: 'Pontos Fortes e a Melhorar',
                            icon: 'fa-tasks',
                            url: '/'
                        },
                        {
                            name: 'Ranking',
                            icon: 'fa-trophy',
                            url: '/'
                        },
                        {
                            name: 'O que mais cai?',
                            icon: 'fa-check-circle',
                            url: '/'
                        }
                    ]
                },
                {
                    name: 'Material Extra',
                    icon: 'fa-graduation-cap',
                    url: '/usuarios/...'
                },
                {
                    name: 'SISU e ENEM',
                    icon: 'fa-graduation-cap',
                    url: '/usuarios/...'
                }
            ]
        }

        return EstudaAPI.instance.menus
    }
}
