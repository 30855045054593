import { useEffect, useState } from "react"
import EstudaAPI from "../../services/EstudaAPI"

export default () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        EstudaAPI.usuario.turma.all().then(function (response) {
            setData(response)
            setLoading(false)
        }).catch(function (response) {
            setError(true)
            setLoading(false)
        })
    }, [])

    if (loading) {
        return ("caregando...");
    }

    return (
        <>
            <h1>Questões</h1>

            Escolha a disciplina e o conteúdo

            <div style={{backgroundColor: '#fff'}}>
                Filtros...
            </div>

            <div class="row mt-3">
                <div class="col col-sm-3 col-xs-12">
                    col
                </div>

                <div class="col col-sm-3 col-xs-12">
                    col
                </div>

                <div class="col col-sm-3 col-xs-12">
                    col
                </div>

                <div class="col col-sm-3 col-xs-12">
                    col
                </div>
            </div>
        </>
    )
}
