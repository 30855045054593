import { useEffect, useState } from "react"
import UsuarioTurmaAlunos from "../../components/UsuarioTurmaAlunos"
import UsuarioTurmaTimeline from "../../components/UsuarioTurmaTimeline"
import EstudaAPI from "../../services/EstudaAPI"

export default () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        EstudaAPI.usuario.turma.all().then(function (response) {
            setData(response)
            setLoading(false)
        }).catch(function (response) {
            setError(true)
            setLoading(false)
        })
    }, [])

    if (loading) {
        return ("caregando...");
    }

    return (
        <>
            <h3 class="d-flex align-items-center gap-2">
                <img src="https://img.estuda.com/img/30x30/2/0/jpg/https://d23vy2bv3rsfba.cloudfront.net/empresas_logos/0_ebc9a8dbacabda5412193832e04578c7_9.png?1649783528.114" alt="" class="rounded-circle border"/>
                Nome da Empresa
            </h3>

            <h1>Nome Turma</h1>

            <div style={{backgroundColor: '#fff'}}>
                Filtros...
            </div>

            <div class="row mt-3">
                <div class="col col-sm-8 col-xs-12">
                    <UsuarioTurmaTimeline/>
                </div>

                <div class="col col-sm-4 col-xs-12">
                    <UsuarioTurmaAlunos/>

                    <div>
                        Calendário
                    </div>
                </div>
            </div>
        </>
    )
}
