export default () => {
    return (
        <div class="my-3">
            <img
                src="https://i.imgur.com/410oPtJ.png"
                width="100%"
                height="300"
                border="0"/>
        </div>
    )
}
